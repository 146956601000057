import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AxiosError } from 'pleinchamp-api-client/node_modules/axios';

// Typing to match more or less SWR API so we can easily integrate the lib in case of need
type ResponseInterface<Data, Error> = {
  data?: Data;
  error?: Error;
  isValidating?: boolean;
  retryCallback?: () => void;
  initialData?: Data;
};
export type PlcApiResponseInterface<Data> = ResponseInterface<Data, AxiosError>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FetcherFn<Data, Args extends any[]> = (...args: Args) => Promise<Data>;

type PlcApiCallConfig<Data> = {
  initialData?: Data;
  deduping?: boolean;
  enabled?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePlcApiCall<Data, Args extends any[]>(
  fn: FetcherFn<Data, Args>,
  args: Args,
  config: PlcApiCallConfig<Data> = {}
): PlcApiResponseInterface<Data> {
  const { initialData, deduping = true, enabled = true } = config;

  const [data, setData] = useState(initialData);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [isValidating, setIsValidating] = useState(enabled && !initialData);

  const previousArgs = useRef<Args | undefined>(undefined);
  const firstCall = useRef(Boolean(initialData));
  const shouldDisplayLoading = enabled && !initialData;

  const fetcherCallback = useCallback(() => {
    if (shouldDisplayLoading) {
      setIsValidating(true);
    }
    try {
      fn(...args)
      .then(result => {
        setError(undefined);
        setData(result);
      })
      .catch(e => {
        console.error('Error on api call', e);
        setError(e);
      })
      .finally(() => {
        setIsValidating(false);
      });
    }
    catch (e: any){
      console.log("erreur api", e)
    }
  }, [args]);

  useEffect(() => {
    if (!enabled || (deduping && isEqual(args, previousArgs.current))) {
      return;
    }
    previousArgs.current = args;
    if (data && firstCall.current && deduping) {
      firstCall.current = false;
    } else {
      fetcherCallback();
    }
  }, [...args, enabled, deduping]);

  return { data, error, isValidating, retryCallback: fetcherCallback };
}
