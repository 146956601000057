import { ContentTagList } from '../ContentTagList/ContentTagList.component';
import { LinkProps } from 'next/link';
import { Content, Media } from 'pleinchamp-api-client';
import React, { ComponentProps, FC } from 'react';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { PlcImg } from '@components/PlcImg/PlcImg.component';
import { ProTag } from '@components/ProTag/ProTag.component';
import { Text } from '@components/Text/Text.component';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Spacing } from '@styles/Spacing.style';
import { buildTagList } from '@utils/card';
import classnames from 'classnames';
import styles from './HighlightedContent.module.scss';

type Props = Omit<ComponentProps<'article'>, 'content'> & {
  content: Content;
  linkProps: LinkProps;
  linkI18nKey: string;
  media?: Media;
  header?: JSX.Element;
  description?: string;
};

const ROOT_CLASS = `${styles['highlighted-content']} flex column row-up-m align-center-up-m`;

export const HighlightedContent: FC<Props> = ({
  content,
  media,
  header,
  description,
  linkProps,
  linkI18nKey,
  className,
  ...rest
}) => {
  const { activityCodes, interestCodes, title, isPro } = content;
  const tags = buildTagList(activityCodes, interestCodes);
  const rootClass = classnames(ROOT_CLASS, className);

  return (
    <PlcAnchor linkProps={linkProps} niveau3={title}>
      <article className={rootClass} {...rest}>
        <figure className="plc-mb-m plc-mb-up-m-0">
          {media && (
            <PlcImg
              alt={media.alt}
              loader={<PlcImgLoader height={497} uniqueKey={content.slug} width={497} />}
              plcSrc={{ src: media.url, type: 'external' }}
            />
          )}
          <ContentTagList isHighlightContent={true} asLink={false} className="justify-end" oneLine={true} tags={tags} />
        </figure>
        <div className={`${styles['highlighted-content-main']} flex column`}>
          <header className="plc-mb-m plc-mb-up-m-basis">
            {header}
            <Text className={isPro ? 'plc-mb-s' : ''} tag="p" variant="h3">
              {title}
            </Text>
            {isPro && <ProTag />}
          </header>
          {description && (
            <Text className="plc-mb-m plc-mb-up-m-basis" variant="small">
              {description}
            </Text>
          )}
          <div>
            <TextWithArrow i18nKey={linkI18nKey} variant="p" />
          </div>
        </div>
      </article>
    </PlcAnchor>
  );
};

export const HighlightedContentLoader: FC<ComponentProps<'div'> & { uniqueKey?: string }> = ({ className,  uniqueKey,...rest }) => {
  const rootClass = classnames(ROOT_CLASS, className);

  return (
    <div className={rootClass} {...rest}>
      <PlcImgLoader className="plc-mb-m" height={497} uniqueKey={`${uniqueKey}-img`} width={497} />
      <PlcTextListLoader
        className="highlighted-content-main flex column"
        textProps={[
          { bottomSpacing: Spacing.s, variant: 'h3', width: 50 },
          { bottomSpacing: Spacing.m, numberOfLine: 3, variant: 'small', width: 100 },
          { variant: 'p', width: 30 },
        ]}
      />
    </div>
  );
};
