import React, { ComponentProps } from 'react';
import { GenericAd } from '@adverts/components/GenericAd.component';
import { ADVERT_FORMAT_ID } from './Adverts.contants';

export function insertAdvertInArray(
  inputArray: (JSX.Element | null)[],
  position: number,
  maxSize: number,
  formatId: ADVERT_FORMAT_ID,
  advertProps?: Omit<ComponentProps<typeof GenericAd>, 'formatId'>
) {
  const AdComponent = <GenericAd key={`ad-${position}-${formatId}`} formatId={formatId} {...advertProps} />;
  inputArray.splice(position, 0, AdComponent);
  return inputArray.slice(0, maxSize);
}
