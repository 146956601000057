import { PlcMessage } from './PlcMessage.component';
import React, { ComponentProps, ComponentPropsWithRef, FC } from 'react';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { SpinnerIcon } from '@components/icons/Spinner.icon';
import classnames from 'classnames';

export type PlcMessageWithRetryProps = ComponentPropsWithRef<'div'> & {
  plcMessageProps?: ComponentProps<typeof PlcMessage>;
  retryCallback?: () => void;
  isLoading?: boolean;
};

const PlcMessageWithRetry: FC<PlcMessageWithRetryProps> = ({
  plcMessageProps = {},
  retryCallback,
  isLoading,
  className,
  ...rest
}) => {
  const { flavour = 'dark', ...restPlcMessageProps } = plcMessageProps;
  const rootClass = classnames('plc-message-with-retry', 'text-center', 'plc-p-m', className);
  return (
    <div className={rootClass} {...rest}>
      <PlcMessage className="text-center" flavour={flavour} {...restPlcMessageProps} />
      {retryCallback && (
        <PrimaryButton
          className="plc-mt-basis"
          disabled={isLoading}
          isSmall
          onClick={retryCallback}
          textProps={{ i18nKey: isLoading ? undefined : 'common.retry' }}
        >
          {isLoading && <SpinnerIcon height={34} width={34} />}
        </PrimaryButton>
      )}
    </div>
  );
};

export { PlcMessageWithRetry };
