import { AdvertService } from './Advert.service';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { userSelectors } from '@user/business/User.store';
import { GeneralContext } from 'features/business/app.contexts';

type Props = { pageId: string | number };

export const useAdvert = ({ pageId }: Props) => {
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const isSSO = useSelector(userSelectors.isSSO);
  const { windowWidth } = useContext(GeneralContext);
  const [isServiceConfigured, setIsServiceConfigured] = useState(false);
  const hasWidth = Boolean(windowWidth);

  useEffect(() => {
    window.gtag_enable_tcf_support = true;
    if (process.env.NEXT_STATIC_ADS_STATUS === 'disabled' || !hasWidth || isSSO) {
      return;
    }

    if (!isServiceConfigured) {
      AdvertService.pageId = pageId;
      AdvertService.isMobile = isMobile;
      setIsServiceConfigured(true);
    }
  }, [hasWidth, isMobile, isSSO]);
};
